.photoblock {
    width: 100%;
    height: 100%;
  .photoblock__img {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
  }
}
