@import "variables";
@import "mixins";
@import url("https://fonts.googleapis.com/css2?family=PT+Mono&display=swap");

body {
    font-family: "PT Mono", monospace;
    font-size: 1em;
    color: #333333;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    @include max-screen(sm) {
        size: 18px;
    }
    margin: 0;
    padding: 0;
}

// PARAGRAPHS & LISTS =============================================== *

p, ul, ol { 
	font: {
        font-family: "PT Mono", monospace;
        weight: regular;
        size: 1em; // 16px
    }
    color: #333333;
    line-height: 1.5;
    @include max-screen(sm) {
        font-size: 1em; // 16px
    }
}

ul, ol {
    &.list {
        size: 1em; // 16px
        &.list--small {
            font-size: 0.875em; // 14px
            line-height: 1.4;
            max-width: 70%;
            @include max-screen(sm) {
                max-width: none;
                padding-left: 20px;
            }
            .list__item {
                margin-bottom: 8px;
            }
        }
    }
}
ol {
    &.list {
        &.list--small {
            max-width: 85%;
        }
    }
}

p {
    &.text {
        &.text--small {
            font-size: 0.875em; // 14px
            line-height: 1.4;
            ul, ol {
                font-size: 0.875em; // 14px
                line-height: 1.4;
            }
        }
    }
}


// HEADLINES =============================================== *

h1, h2, h3, h4 {
    font-family: "PT Mono", monospace;
    color: #333333;
}

h1 {
    font: {
        weight: regular;
        size: 2.5em; // 40px
        style: italic;
    }
    line-height: 1.3;
    letter-spacing: 0.35px;
    @include max-screen(sm) {
        font-size: 2.0em; // 32px
    }
}

h2 {
    font: {
        weight: bold;
        size: 1.5em; // 24px
        style: italic;
    }
    line-height: 1.3;
    letter-spacing: 0.3px;
    @include max-screen(sm) {
        font-size: 1.25em; // 20px
    }
}

h3 {
    font: {
        weight: bold;
        font-size: 1.25em; // 20px
        style: italic;
    }
    line-height: 36px;
    @include max-screen(sm) {
        font-size: 1.125em; // 18px
    }
}


// LINKS =============================================== *

a, a:visited {
    transition: all 200ms ease-in-out;
}


// FORM =============================================== *



