@import "../../styles/globals";

.nav {
  .nav__img {
    background-image: url("../../images/edge.jpeg");
    width: 100%;
    height: 50vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: 60%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    position: relative;
    .nav__logo {
      width: 80%;
      max-width: 100%;
      //padding: 0 60px;
      position: absolute;
      bottom: -8px;
    }
  }
}

@include max-screen(xl) {
  .nav {
    .nav__img {
      background-position-y: 55%;
      .nav__logo {
        width: 100%;
        bottom: -4px;
      }
    }
  }
}

@include max-screen(lg) {
  .nav {
    .nav__img {
      background-position-y: 55%;
      .nav__logo {
        width: 100%;
        bottom: -4px;
      }
    }
  }
}

@include max-screen(md) {
  .nav {
    .nav__img {
      height: 30vh;
      background-position-y: 65%;
      .nav__logo {
        width: 100%;
        bottom: -2px;
      }
    }
  }
}
