@import "../../styles/globals";

.contentblock {
    padding: 40px 20%;
}

@include max-screen(md) {
    .contentblock {
        padding: 24px 10%;
    }
}