.wrapper {
  width: 100vw;
  height: 100vh;
  border-top: 20px solid #339499;
  a, a:visited {
    font-weight: bold;
    color: #3b75af;
    &:hover {
      color: #333333;
    }
  }
}
